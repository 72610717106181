import {EventBus} from '../../helpers/event-bus'
import {setToken} from '../../api'

const state = {
  timeCount: 0,
  time: 0,
  interval: 1000,
  count: null,
  timeOut: null,
  iniciado: false
};

const getters = {
  pegaTimeCount: (state) => {
    return state.timeCount
  }
};

const actions = {
  start({commit}) {
    commit('SET_INICIADO', true);
    if (sessionStorage.getItem('token_expiration') != null) {
      commit('SET_TIME', sessionStorage.getItem('token_expiration'))
    }

    function contador() {
      const valor = state.timeCount - state.interval;
      if (state.timeCount > 0) commit('SET_TIMECOUNT', valor);
      sessionStorage.setItem('token_expiration', state.timeCount)
    }

    function sessaoExpirada() {
      commit('CLEAR_COUNT');
      commit('SET_INICIADO', false);
      EventBus.$emit('RENOVAR_LOGIN', true)
    }

    commit('SET_TIMECOUNT', state.time);
    commit('SET_COUNT', setInterval(contador, state.interval));
    commit('SET_TIMEOUT', setTimeout(sessaoExpirada, state.time))
  },

  cancelarToken({commit}) {
    commit('CLEAR_COUNT');
    commit('CLEAR_TIMEOUT');
    commit('SET_INICIADO', false)
  },

  renovarToken({commit}, data) {
    setToken(data.token, sessionStorage.getItem('funcionario_id')); // Atualizando token
    this.dispatch('token/cancelarToken');
    sessionStorage.setItem('token', data.token);
    sessionStorage.setItem('token_expiration', data.token_expiration * 60 * 1000);
    this.dispatch('token/start')
  },

  setarToken({commit}, token, user) {
    setToken(token, user)
  }
};

const mutations = {
  SET_TIME(state, time) {
    state.time = time
  },
  SET_TIMEOUT(state, timeOut) {
    state.timeOut = timeOut
  },
  SET_TIMECOUNT(state, timeCount) {
    state.timeCount = timeCount
  },
  SET_COUNT(state, count) {
    state.count = count
  },
  SET_INICIADO(state, iniciado) {
    state.iniciado = iniciado
  },
  CLEAR_COUNT(state) {
    state.timeCount = 0;
    clearInterval(state.count)
  },
  CLEAR_TIMEOUT(state) {
    state.time = 0;
    clearTimeout(state.timeOut)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
